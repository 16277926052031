<template>
  <div v-if="version_info != null">
    <a-popover trigger="click" placement="bottom" overlayClassName="pop-download">
      <a-tabs size="small" v-model="selectPlatform" slot="content" :style="{ width: '29rem' }" style="text-align: center">
        <a-tab-pane v-for="platform in version_info.platforms" v-bind:key="platform.type">
          <span slot="tab">
            <a-icon type="windows" v-if="platform.type == 'Windows'"/>
            <a-icon type="apple" v-else-if="platform.type == 'MacOS'"/>
            {{ platform.type }}
          </span>
          <a-table class="table" :columns="columns[platform.type]" :data-source="data[platform.type]" size="small" :pagination="false" :bordered="false"
            tableLayout="auto" :rowClassName="(record, index) => 'real-download'">
            <span style="white-space:pre-wrap" slot="system" slot-scope="text">{{ text }}</span>
            <div style="text-align:right" slot="type" slot-scope="text, record">{{ text }}&nbsp;
              <a-popover trigger="click">
                <template slot="content">
                  <template v-if="record.x86Hash && record.x86Hash.length > 0">
                    <h4>X86校验信息</h4>
                    <template v-for="{name, value} in record.x86Hash">
                      <pre style="margin:0" v-bind:key="name" v-if="value">{{ name.padStart(6,' ') }}: {{ value }}</pre>
                    </template>
                    <a-divider style="margin: 8px 0 0 0" />
                    <h4>X64校验信息</h4>
                  </template>
                  <h4 v-else>校验信息</h4>
                  <template v-for="{name, value} in record.x64Hash">
                    <pre style="margin:0" v-bind:key="name" v-if="value" >{{ name.padStart(6,' ') }}: {{ value }}</pre>
                  </template>
                </template>
                <a-icon type="info-circle"/>
              </a-popover></div>
            <a-badge slot="x86-download" slot-scope="link, record" v-if="isWin">
              <span slot="count" class="tuijian" v-if="platform.type == devicePlatform && !isX64 && record.recommend">推荐</span>
              <a :href="link" target="_blank" v-if="link"><a-button icon="download" type="link"/></a>
              <a  v-else><a-button icon="frown" type="link" style="color: #999; cursor: not-allowed"/></a>
            </a-badge>
            <a-badge slot="x64-download" slot-scope="link, record">
              <span slot="count" class="tuijian" v-if="platform.type == devicePlatform && isX64 && record.recommend">推荐</span>
              <a :href="link" target="_blank" v-if="link"><a-button icon="download" type="link"/></a>
              <a  v-else><a-button icon="frown" type="link" style="color: #999; cursor: not-allowed"/></a>
            </a-badge>
          </a-table>
        </a-tab-pane>
      </a-tabs>
      <a-button class="download-btn" @click="() => getPlatform()">立即下载<a-icon type="cloud-download"/></a-button>
    </a-popover>
    <a-button class="question-btn" type="primary" @click="gotoQA"><a-icon type="question" /><br />常见问题</a-button>
    <p class="info">
      软件版本： {{ version_info.version }}
      <a-divider type="vertical" />
      <a-popover trigger="click" placement="bottom" overlayClassName="pop-history" v-model="history_pop_visable">
        <a-tabs size="small" v-model="selectPlatform" slot="content" :style="{ width: '23rem' }" style="text-align: center">
          <a-tab-pane v-for="platform in version_info.platforms" v-bind:key="platform.type">
            <span slot="tab">
              <a-icon type="windows" v-if="platform.type == 'Windows'"/>
              <a-icon type="apple" v-else-if="platform.type == 'MacOS'"/>
              {{ platform.type }}
            </span>
            <template v-if="platform.type != 'MacOS'">
              <a-button type="primary" @click="() => showDrawer(platform.type, 'X86 (32位)')">X86 (32位)</a-button>
              <a-divider type="vertical"/>
              <a-button type="primary" @click="() => showDrawer(platform.type, 'X64 (64位)')">X64 (64位)</a-button>
            </template>
            <a-button v-else type="primary" @click="() => showDrawer(platform.type, 'X64 (64位)')">X64 (64位)</a-button>
          </a-tab-pane>
        </a-tabs>
        <a-button type="link" class="updateHistory" @click="() => getPlatform()">更新历史</a-button>
      </a-popover>
    </p>
    <p class="info">更新日期： {{ version_info.date }}</p>
    <template v-for="(platform, index) in version_info.platforms">
      <template v-for="r in platform.releases">
        <p class="info" v-bind:key="`${index}-${r.name}-${r.type}`" v-if="index == 0">
          <span v-if="r.type.includes('安装包') && r.hidesystem != true">支持系统： {{ platform.type }} {{ r.name }}</span>
        </p>
        <p class="info info-notitle" v-bind:key="`${index}-${r.name}-${r.type}`" v-else>
          <span v-if="r.type.includes('安装包') && r.hidesystem != true">{{ platform.type }} {{ r.name }}</span>
        </p>
      </template>
    </template>
    <a-drawer
      :title="drawer_title"
      placement="right"
      :closable="false"
      :visible="drawer_visible"
      @close="onDrawerClose"
      width="34rem"
      :bodyStyle="{ padding: '0 0 0 2rem' }"
    >
      <a-tabs
        size="small"
        v-model="drawer_selected"
        :style="{ width: '32rem' }"
        class="history_version"
      >
        <a-tab-pane v-for="info in drawer_content" v-bind:key="info.version">
          <span slot="tab" v-if="version_info.version === info.version">
            v{{ info.version }} (当前版本)
          </span>
          <span slot="tab" v-else> v{{ info.version }} </span>
          <pre>{{ info.desction }}</pre>
        </a-tab-pane>
      </a-tabs>
    </a-drawer>
  </div>
</template>

<script>
import axios from "axios";
import { getUA } from "@/js/getUserUAInfo";
const columnsAll = [
  {
    title: "系统 / 发行版",
    dataIndex: "system",
    key: "system",
    scopedSlots: { customRender: "system" },
  },
  {
    title: "类型",
    dataIndex: "type",
    key: "type",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "X86下载",
    dataIndex: "x86",
    key: "x86",
    width: "76px",
    scopedSlots: { customRender: "x86-download" },
  },
  {
    title: "X64下载",
    dataIndex: "x64",
    key: "x64",
    width: "76px",
    scopedSlots: { customRender: "x64-download" },
  },
];
const columnsX64 = [
  {
    title: "系统 / 发行版",
    dataIndex: "system",
    key: "system",
    scopedSlots: { customRender: "system" },
  },
  {
    title: "类型",
    dataIndex: "type",
    key: "type",
    scopedSlots: { customRender: "type" },
  },
  {
    title: "下载",
    dataIndex: "x64",
    key: "x64",
    width: "76px",
    scopedSlots: { customRender: "x64-download" },
  },
];
const columns = {
  Windows: columnsAll,
  MacOS: columnsX64,
  Linux: columnsX64
}
const {isWin, isWin64, isLinux64, isIntelMac, isMac, isLinux, isAndroid, isIphone, isIpad, isX64} = getUA()
const UpdatePlatform = {
  Windows: "win32",
  MacOS: "darwin",
  Linux: "linux",
};
const UpdateArch = {
  "X64 (64位)": "x64",
  "X86 (32位)": "ia32",
};
import { Button, Icon, Popover, Table, Tabs, Drawer, Divider, Badge } from 'ant-design-vue'
export default {
  components: {
    'a-button': Button,
    'a-divider': Divider,
    'a-drawer': Drawer,
    'a-icon': Icon,
    'a-popover': Popover,
    'a-tab-pane': Tabs.TabPane,
    'a-table': Table,
    'a-tabs': Tabs,
    'a-badge': Badge,
  },
  data() {
    var devicePlatform = ""
    if (isWin) devicePlatform = "Windows";
    else if (isMac) devicePlatform = "MacOS";
    else if (isLinux) devicePlatform = "Linux";
    axios.get("/version_info.json").then((res) => {
      this.version_info = res.data;
      this.data = {};
      this.history_info = {};
      let i = 0;
      for (const platform of res.data.platforms) {
        this.data[platform.type] = [];
        this.history_info[platform.type] = {};
        for (const r of platform.releases) {
          let d = {
            key: String(i++),
            platform: platform.type,
            system: r.name,
            type: r.type,
            x86: r.x86,
            x64: r.x64,
            x86Hash: r['x86-hash'],
            x64Hash: r['x64-hash'],
            recommend: r.recommend
          };
          this.data[platform.type].push(d);
        }
      }
      console.log(this.data)
    });
    return {
      data: null,
      selectPlatform: "",
      devicePlatform,
      columns,
      version_pop_visable: false,
      history_pop_visable: false,
      version_info: null,
      history_info: null,
      drawer_title: "",
      drawer_content: [],
      drawer_selected: "",
      drawer_visible: false,
      isWin, isWin64, isLinux64, isIntelMac, isMac, isLinux, isAndroid, isIphone, isIpad, isX64
    };
  },
  methods: {
    getPlatform() {
      this.selectPlatform = this.devicePlatform
    },
    gotoQA() {
      this.$router.push("/qa");
    },
    async getHistoryInfo(devType, devArch) {
      const platform = UpdatePlatform[devType];
      const arch = UpdateArch[devArch];
      let res = await axios.get(`/api/v1/getHistory/${platform}/${arch}`);
      this.history_info[devType][devArch] = res.data;
      console.log(this.history_info);
    },
    showDrawer(devType, devArch) {
      if (!this.history_info[devType][devArch]) {
        this.getHistoryInfo(devType, devArch).then(() => {
          this.drawer_title = `${devType} ${devArch} 的更新历史`;
          this.drawer_content = this.history_info[devType][devArch];
          this.drawer_selected = this.drawer_content[0].version;
          this.drawer_visible = true;
          this.history_pop_visable = false;
        });
      } else {
        this.drawer_title = `${devType} ${devArch} 的更新历史`;
        this.drawer_content = this.history_info[devType][devArch];
        this.drawer_selected = this.drawer_content[0].version;
        this.drawer_visible = true;
        this.history_pop_visable = false;
      }
    },
    onDrawerClose() {
      this.drawer_visible = false;
    },
  },
};
</script>

<style>
.ant-table-row.real-download > .ant-table-row-cell-break-word {
  text-align: center;
}
</style>

<style scoped>
.tuijian {
  font-weight: bold;
  -webkit-user-select:none;
  user-select:none;
  color: #1890ff;
}
.info {
  font-family: "微软雅黑", "黑体";
  font-size: 15px;
  color: white;
  margin: 0 0;
  white-space: nowrap;
}
.updateHistory {
  font-family: "微软雅黑", "黑体";
  color: white;
  margin: 0 0;
  padding: 0 10px;
  white-space: nowrap;
  height: initial;
}
.updateHistory:active {
  color: #ddd;
  border-style: solid;
  border-width: 1px;
  border-color: #ddd;
}
.updateHistory:hover {
  color: #f7f7f7;
  border-style: dashed;
  border-width: 1px;
  border-color: #f7f7f7;
}
.info-notitle {
  padding-left: 5rem;
}
.platform-icon {
  font-size: 1.25rem;
  text-align: center;
}
.real-download {
  text-align: center;
}
.question-btn {
  float: right;
  height: 64px;
  border-radius: 0;
  border: 0;
}
.download-btn {
  margin-bottom: 10px;
  border-radius: 0;
  border: 0;
  color: #f76414 !important;
  background-color: #fff33d;
  font-size: 32px;
  padding: 10px 32px;
  width: 240px;
  height: 64px;
}
.download-btn:hover,
.download-btn:focus {
  background-color: #e8da00;
}
.download-btn:active {
  background-color: #dccf00;
}
.download-btn i {
  margin-left: 16px !important;
}
.text-left {
  text-align: left;
}
.pop-history-header >>> .ant-collapse-item > .ant-collapse-header {
  padding: 4px 16px;
  padding-left: 40px;
}
.history_version >>> .ant-tabs-small-bar .ant-tabs-tab {
  margin: 0 16px 0 0;
  padding: 8px 8px;
}
</style>

<style>
.pop-download .ant-popover-inner {
  background-color: #f7f7f7;
}
.pop-download .ant-table-thead > tr > th {
  text-align: center;
}
</style>