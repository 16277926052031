<template>
  <div class="topbg">
    <div class="mainWrapper">
      <div class="shield" />
      <div class="slider sliderwimg">
        <a-carousel autoplay>
            <img v-bind:key="i"  v-for="i in carouselNum"
              v-bind:src="require(`../assets/img_s/s${i}.jpg`)"
            />
        </a-carousel>
      </div>
      <DownloadArea class="downloads"/>
    </div>
  </div>
</template>

<script>
import DownloadArea from './DownloadArea.vue'
import { Carousel } from 'ant-design-vue'
export default {
  components: {
    DownloadArea,
    'a-carousel': Carousel
  },
  data() {
    return {
      carouselNum: [1, 2, 3, 4],
    };
  },
};
</script>

<style scoped>
.downloads {
  position: absolute;
  min-width: 244px;
  top: 150px;
  left: 611px;
  text-align: left;
}
.topbg {
  background: url("~@/assets/bg.jpg") top center no-repeat #00aa83;
  min-height: 502px;
}
.mainWrapper {
  width: 980px;
  position: relative;
  margin: 0 auto;
}
.shield {
  background: url("~@/assets/shield.png") no-repeat;
  width: 179px;
  height: 196px;
  position: absolute;
  top: 259px;
  left: 418px;
  z-index: 2;
}
.slider {
  position: absolute;
  z-index: 1;
  top: 107px;
  left: 46px;
}
.sliderwimg {
  width: 438px;
  height: auto;
  image-rendering: crisp-edges;
}
.slider >>> .slick-dots-bottom {
  bottom: -145px;
}
.slider >>> .slick-dots {
  height: 14px;
}
.slider >>> .slick-dots li {
  border: 1px solid white;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  margin: 0 0.5rem;
  background-color: #FFFFFF4B;
}
.slider >>> .slick-dots li button,
.slider >>> .slick-dots li.slick-active button {
  width: 12px;
  height: 12px;
  background-color: #FBEF3B;
  border: 1px solid #FBEF3B;
  border-radius: 100%;
  opacity: 0;
}
.slider >>> .slick-dots li.slick-active button {
  opacity: 1;
}
</style>