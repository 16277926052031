<template>
  <div class="reason">
    <p class="title">使用百田游戏安全管家的四大理由</p>
    <a-row>
      <template v-for="(item, index) in reason">
        <a-col
          :xxl="{ span: 6, offset: 5 }"
          :lg="{ span: 8, offset: 3 }"
          :md="{ span: 22, offset: 1 }"
          v-bind:key="index + 1"
          v-if="index%2==0"
        >
          <div :class="`reasons-icon reasons-icon-${index + 1}`"></div>
          <p class="reason-title">{{ item[0] }}</p>
          <p class="reason-text">{{ item[1] }}</p>
        </a-col>
        <a-col
          :xxl="{ span: 6, offset: 2 }"
          :lg="{ span: 8, offset: 3 }"
          :md="{ span: 22, offset: 1 }"
          v-bind:key="index + 1"
          v-else
        >
          <div :class="`reasons-icon reasons-icon-${index + 1}`"></div>
          <p class="reason-title">{{ item[0] }}</p>
          <p class="reason-text">{{ item[1] }}</p>
        </a-col>
      </template>
    </a-row>
  </div>
</template>

<script>
import { Row, Col } from 'ant-design-vue'
export default {
  components: {
    'a-row': Row,
    'a-col': Col,
  },
  data() {
    return {
      reason: [
        ["更安全", "百田官方客户端，登录更有保障，让你玩游戏更加安全！"],
        ["更过瘾", "不用下载第三方浏览器就能玩游戏\n更有全屏模式，去除干扰玩游戏更过瘾！"],
        ["更流畅", "一键扫描修复各种游戏加载问题，让你玩游戏加流畅！"],
        ["更稳定", "专业回源模式，绕过互联网不稳定因素，让你玩游戏更稳定！"],
      ],
    };
  },
};
</script>

<style scoped>
.reason {
  padding: 20px 0 10px;
  text-align: left;
  background-color: #FFF;
  color: rgba(0,0,0,0.85);
}
.title {
  color: #008969;
  font-size: 26px;
  text-align: center;
}
.reasons-icon {
  background: url("~@/assets/sp.jpg") no-repeat;
  width: 195px;
  height: 200px;
  float: left;
}
.reasons-icon-4 {
  background-position: 0 0;
}
.reasons-icon-3 {
  background-position: -195px 0;
}
.reasons-icon-1 {
  background-position: -390px 0;
}
.reasons-icon-2 {
  background-position: -585px 0;
}
.reason-title {
  font-size: 26px;
  color: #5c5c5c;
  margin-top: 30px;
  margin-bottom: 0px;
}
.reason-text {
  font-size: 16px;
  color: #858585;
  margin-top: 0px;
  white-space: pre-wrap;
}
</style>